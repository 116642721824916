::selection {
  background: #696969;
  color: #fff;
}

body {
  background-color: #fff;
}

.App {
  min-height: 100vh;
  text-align: center;
  box-sizing: border-box;
}

/* __________ HEADER __________  */
.App-header {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: center; */
  font-size: calc(10px + 2vmin);
  color: white;
  background-image: url("./asset/header-background.jpg");
  background-position: initial;
  background-attachment: fixed;
  background-size: auto;
  background-repeat: no-repeat;
  height: 16vw;
}

.Header-top {
  background-color: #00000085;
  height: 8vw;
}

.App-logo {
  float: left;
  margin-top: 2.5vw;
  margin-left: 2vw;
  margin-bottom: 1.5vw;
  height: 4vw;
}

.Header-bottom {
  height: 8vw;
}

.Title-paragraph {
  float: left;
  margin-left: 4vw;
  margin-top: 2vw;
  margin-bottom: 1vw;
  color: #bababa;
}

.Header-image-div {
  position: absolute;
  right: 10vw;
  top: 2vw;
  /* transform: translate(0, -50%); */
}

.Language-bar {
  position: absolute;
  float: right;
  margin: 2vh 5vh 2vh 0;
  right: 2rem;
  top: 17vw;
  font-size: 2rem;
  display: flex;
  align-items: center;
  z-index: 100;
}

.selectLang {
  display: flex;
  align-items: center;
  margin-right: 2rem;
}
.selectLang > p {
  font-size: 1rem;
}

.globeIcon {
  margin-right: 0.2rem;
}

.printIcon:hover,
.selectLang:hover {
  cursor: pointer;
}

.welcomeText {
  position: absolute;
  left: 60px;
  display: flex;
  align-items: center;
  z-index: 2;
  text-align: left;
}

/*----Header End -----*/

/* __________ LANGUAGE COMPONENT __________  */
.ModalWrapper {
  background: rgba(0, 0, 0, 0.75);
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 101;
  top: 0;
  backdrop-filter: blur(1px);
  -webkit-backdrop-filter: blur(1px);
}

.ModalSelection {
  background-color: white;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: black;
  padding: 1rem 2rem;
  width: 30rem;
  height: min-content;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 6px solid #385a60;
  border-radius: 0.25rem;
  box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%),
    0 0px 0 1px rgb(10 10 10 / 2%);
}

.modalDropdown > p {
  padding: 1.5rem 0rem 0rem 0rem;
  font-size: 1.4rem !important;
}

.modalDivider {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}
.modalDropdown {
  width: 45%;
}
.langSelect {
  outline: none;
  width: 100%;
  padding: 4px 10px;
  appearance: none;
  background-image: url("./asset/down_arrow.svg");
  background-position: calc(100% - 14px);
  background-repeat: no-repeat;
  background-size: 9px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.473);
  cursor: pointer;
}

.ModalButton {
  border-radius: 5px;
  width: 10rem;
  background-color: #000000;
  border: none;
  margin: 2rem 0;
  outline: none;
  color: white;
  height: 2rem;
  font-weight: 700;
  cursor: pointer;
}

.crossIcon {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

/*----Language Component End -----*/

.oldPump {
  width: 14vw;
}

@media (min-width: 1700px) {
  .App-header {
    background-size: contain;
  }
}

/* __________ SEARCH AREA __________  */
#search-area {
  display: inline-block;
  width: 80vw;
  position: relative;
  z-index: 1;
  max-width: 1100px;
  margin: 40px auto;

  background-color: rgb(241, 234, 224);
  padding: 0.5vw;
  z-index: 50;
}

/* __________ SEARCH FILTERS __________  */

#select-filters {
  /* background-color: rgb(241, 234, 224); */
  float: left;
  position: relative;
  /* padding-bottom: 0.5vw; */
  background-color: white;
  margin-bottom: 0.5vw;
  /* border-radius: 9999px; */
}

#select-filters select {
  width: 20rem;
  outline: none;
  appearance: button;
  -webkit-appearance: button;
  padding: 4px 10px;
  border: none;
  margin: 0;
  appearance: none;
  background-color: #fff;
  /* border-radius: 9999px; */
  cursor: pointer;
  background-image: url("./asset/down_arrow.svg");
  background-position: calc(100% - 14px);
  background-repeat: no-repeat;
  background-size: 9px;
  /* margin-bottom: 0.5vw; */
}

.toggleArrow {
  margin-top: 1px;
  height: 100%;
  width: 12px;
}

/* __________ SEARCH FORM __________  */

.search-form {
  display: inline-block;
  position: relative;
  text-align: center;
  width: 100%;
  height: 40px;
  /* border-radius: 9999px; */
  z-index: 100;
  background-color: white;
}

.search-form input[type="search"] {
  background-color: transparent;
  width: calc(100% - 90px);
  position: absolute;
  top: 50%;
  left: 38px;
  transform: translate(0, -50%);
  font-size: 14px;
  color: #5a6674;
  outline: none;
  border: none;
  appearance: none;
}
.search-form button,
.show-brand {
  position: absolute;
  width: 17px;
  height: 60%;
  cursor: pointer;
  border: none;
  background: none;
  outline: none !important;
  padding: 0;
  margin: 0;
}
.search-form button[type="submit"] {
  left: 15px;
  top: 9px;
}
.search-form button.show-list,
.show-brand {
  right: 18px;
  width: 18px;
  height: 100%;
}

.search-form button[type="submit"] svg {
  width: 100%;
  height: 100%;
}

/* __________ SEARCH LIST __________  */
#search-list {
  opacity: 0;
  pointer-events: none;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  background: #eee;
  width: 85%;
  max-height: 30vh;
  overflow: auto;
  margin: -25px auto 0px;
  padding: 18px 10px 0;
  border-radius: 4px;
  text-align: left;
  color: #000;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
    0 0px 0 1px rgba(10, 10, 10, 0.02);

  transition: 0.3s ease opacity;
  z-index: 50;
}
#search-list.active {
  opacity: 1;
  pointer-events: all;
}

.search-item {
  font-size: 14px;
}

.search-item:hover {
  background-color: #fff;
  font-size: 14px;
  cursor: pointer;
}
.search-item:first-of-type {
  margin-top: 7px;
}
.search-item:last-of-type {
  margin-bottom: 5px;
}

.search-result {
  margin: 0 5px;
  vertical-align: center;
}
.search-result.manufacturer {
  display: inline-block;
  max-width: 60%;
}
.search-result.rsk {
  font-size: 0.8em;
  font-style: italic;
}
.search-result.type {
  color: #777;
  font-size: 0.8em;
  float: right;
}
.search-item:hover .search-result.name {
  color: #4aa4b6;
}
.search-item:hover .search-result.rsk {
  color: #f00;
}
.search-item:hover .search-result.type {
  color: #333;
}

.blurred ~ .blurred {
  border-top: none;
  margin-top: 0;
  padding-top: 0;
}

.blurred {
  border-top: 1px solid rgba(0, 0, 0, 0.45);
  margin-top: 2px;
  padding-top: 2px;
  opacity: 0.4;
}

/* __________COMMENTSECTION __________  */
.commentSection {
  text-align: left;
  height: min-content;
  padding: 1rem 3rem;
  display: flex;
  margin-top: -3rem;
}

.commentComment {
  font-weight: 700;
}

.commentComments {
  width: 70%;
  padding: 0rem 0rem 0rem 1rem;
  padding-bottom: 0.7rem;
}
.comments {
  display: flex;
  padding-left: 2rem;
  padding-top: 1rem;
}

/* __________ DISCLAIMER __________  */

.disclaimerContainer {
  background-color: #2d819d;
  color: black;
  margin: 2rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  text-align: center;
}

.disclaimer {
  padding: 2rem;
  margin: 2rem;
  border-radius: 0.25rem;
}

.disclaimer a {
  text-decoration: underline;
  color: black;
  font-weight: 600;
  margin: 0.5rem;
}
.contactus a {
  text-decoration: underline;
  color: white;
  font-weight: 600;
  margin: 0.5rem;
}
.warningSign {
  color: rgb(202, 0, 0);
  font-size: 2rem;
}

.bulletPoints {
  padding: 1rem;
  font-weight: 700;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.bulletPoints > li {
  list-style-type: disc;
}

/* ____________________Accessories_________________ */
.accessories_table {
  width: 100%;
}

.accessories_table th {
  height: 4rem;
  padding: 1rem;
  vertical-align: middle;
}

.accessories_table td {
  padding: 1rem;
  vertical-align: middle;
  border-width: 0 0 1px;
}

.accessoriesImage {
  height: 8rem;
  width: 10rem;
  background-color: white;
}
.accessoryImage {
  object-fit: contain;
  height: 5rem;
}

.accessoryTab {
  /* height: 2rem; */
  padding: 1rem;
  /* border-bottom: 1px solid #2d819d; */
  border-top: 2px solid #2d819d;
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.accessoryTab img {
  padding-left: 1rem;
}
/* __________ FOOTER __________  */

footer {
  background: #222326;
  padding: 20px;
  text-align: center;
  color: white;
  width: 100%;
}

/* __________ RESPONSIVE __________  */

@media (max-width: 1500px) {
  .disclaimer {
    flex-direction: column;
    text-align: left;
  }

  .bulletPoints {
    flex-direction: column;
    align-items: flex-start;
  }
  .topBar {
    top: 9.5rem;
  }

  .Language-bar {
    width: 80vw;
    position: relative;
    top: 0;
    right: 0;
    float: none;
    margin-right: auto;
    margin-left: auto;
    justify-content: flex-end;
  }
}

@media (max-width: 1200px) {
  .accessories_table th {
    height: 3rem;
    padding: 1rem 1rem;
    vertical-align: middle;
  }

  .accessories_table td {
    padding: 1rem 1rem;

    vertical-align: middle;
  }
  .accessoriesImage {
    display: none;
  }
}

@media (max-width: 1000px) {
  .wave-bottom {
    background: linear-gradient(
      90deg,
      #4aa4b7 0%,
      #4aa4b7 40%,
      #2d819d 40%,
      #2d819d 100%
    );
  }
  .wave-top .wave,
  .wave-bottom .wave {
    left: 39.7%;
  }

  #search-area {
    width: 500px;
  }
  .disclaimer {
    width: 80%;
  }
  .commentComments {
    width: 100%;
  }
  .App-logo {
    left: 48px;
    height: 35px;
    top: 13.5px;
  }
}

@media (max-width: 800px) {
  .adapterImg {
    display: none;
  }
}

@media (max-width: 555px) {
  .App-header {
    height: 100px;
  }
  .Header-top {
    height: 50px;
  }
  .Header-bottom {
    height: 50px;
  }
  .Header-image-div {
    display: none;
  }
  #search-area {
    width: 90vw;
    padding: 10px;
    margin: 5px auto;
  }
  .search-result.type {
    display: none;
  }

  .welcomeText {
    /*     align-items: initial; */
    font-size: 0.9rem;
  }

  .LanguageSelection {
    width: 90%;
    align-items: center;
  }

  .modalDivider {
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;
  }

  .modalDropdown {
    width: 70%;
  }

  .ModalSelection {
    width: 95%;
  }
  .ahlsellButtonMobile {
    margin: 0.5rem 0 0.5rem auto;
  }
  .wholesaleButtonMobile {
    margin: 0.5rem 0 0.5rem auto;
  }
  .topBar {
    top: 6.5rem;
  }
}
#noReplacementFound {
  color: #ffffff;
}

@media print {
  .search-form,
  .wave,
  .show-brand,
  .languageSelection,
  .printIcon,
  #filter-manufacturer,
  .selectLang,
  .wave-bottom,
  .adapterDivider,
  .hideOnPrint {
    display: none;
  }

  .productImg {
    max-width: 50% !important;
  }

  .pump-card {
    page-break-inside: avoid;
  }
  #search-area {
    margin-top: 0;
  }
}

.adapter td img {
  height: 8rem;
  margin: 0.5rem;
  margin-top: 1rem;
}

.adapter.content table td,
.adapter.content table th {
  vertical-align: middle;
  /* padding: 0.25em 1em; */
}
